import { Component, Input, OnInit, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { WalletService } from "@services/wallet.service";
import {
  Subscription,
  Subscriptiondetails,
  UserWallet,
  WalletsData,
} from "@interfaces/wallet";
import { User, UserData } from "@interfaces/user";
import { AuthService } from "@services/auth/auth.service";
import { DialogService } from "@ngneat/dialog";
import { environment } from "@environments/environment";
import { Router } from "@angular/router";
import { SubscriptionMenuDropdownComponent } from "../subscription-menu-dropdown/subscription-menu-dropdown.component";

@Component({
  selector: "app-wallet-nav",
  standalone: true,
  imports: [
    CommonModule,
    AngularSvgIconModule,
    SubscriptionMenuDropdownComponent,
  ],
  templateUrl: "./wallet-nav.component.html",
  styleUrls: ["./wallet-nav.component.scss"],
})
export class WalletNavComponent implements OnInit {
  private dialog: DialogService = inject(DialogService);

  wallets: UserWallet[] = [];
  user!: User | undefined;
  companyWallet: any = null;

  isWalletsLoaded = false;

  freeWallet: any = null;
  personalWallet: any = null;

  @Input() showPremiumButton!: boolean;
  subscriptionWallet!: any;

  planText = $localize`:@@elevateYourTrainingLevel:Elevate your training with expert scenarios and top-tier security!`;

  subscription!: Subscription;
  isSubscribed = false;
  buttonText = $localize`:@@upgradeNow:Upgrade Now!`;
  walletFeatures!: any;
  readonly environment = environment;

  constructor(
    private walletService: WalletService,
    private authService: AuthService,
    private router: Router
  ) {
    this.authService.currentUser$.subscribe((userData: UserData | null) => {
      this.user = userData?.user;
    });
  }

  getWallets() {
    this.walletService.getUserWallets().subscribe((data: WalletsData) => {
      this.isWalletsLoaded = true;
      if (data) {
        const wallets = data.wallets;
        this.subscription = data.subscription;

        if (wallets.DEFAULT) {
          this.personalWallet = {
            type: "personal",
            balance: wallets.DEFAULT.balance,
          };
        }

        if (wallets.FREE) {
          this.freeWallet = {
            type: "fidelity",
            balance: wallets.FREE.balance,
          };
        }

        if (wallets.SUBSCRIPTION) {
          this.subscriptionWallet = {
            type: "subscription",
            balance: wallets.SUBSCRIPTION.balance,
          };
        }
        if (wallets.COMPANY) {
          this.companyWallet = {
            type: "company",
            balance: wallets.COMPANY.balance,
          };
        }
        if (this.subscription) {
          if (this.subscription.has_subscription) {
            this.isSubscribed = true;
            this.walletFeatures = JSON.parse(
              this.subscription.subscription_details.features
            );
            this.planText =
              $localize`:@@youAreSubscribed:You are subscribed to the ` +
              this.subscription.subscription_details.plan +
              $localize`:@@enjoyYourTraining: plan. Enjoy your training!`;
            this.buttonText = $localize`:@@viewPlan:View Plan`;
          }
        }
      }
    });
  }

  ngOnInit(): void {
    this.getWallets();
  }
}
