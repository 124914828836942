import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { ToastMessageService } from "@services/toast-message.service";
import { AuthService } from "@services/auth/auth.service";
import { environment } from "@environments/environment";
import { RedirectionService } from "@services/redirection.service";

@Injectable({
  providedIn: "root",
})
export class BackendErrorInterceptor implements HttpInterceptor {
  errorMap: any = {
    "login_social.user_not_found": "Your account is not registered yet.",
    "login_social.disabled_for_user":
      "Social login is disabled for your account.",
    email_taken:
      "There is already an existing user with your email address. Please login",
    social_account_already_used:
      "Your social account is already linked. Please login",
    "social_register.failed": "Oops, something went wrong. Try again",
    account_already_linked: "Your account is already linked.",
    "password.invalid_format": "Password must be at least 8 characters long",
    "old_password.invalid": "Old password is incorrect",
    "avatar.doesnt_exist": "Avatar does not exist",
    invalid_params: "Something went wrong, please try again later",
    "group_invitation_action.unauthorized": "You already managed this request",
    "user.already_belongs_to_group":
      "You already belong to a group, to join this group, you must leave your group first",
    "group.already_full": "This group is already full",
    "member.unauthorized": "Only a group leader can manage join requests",
    "request.invalid": "This request doesnt exist anymore",
    "group_join_action.unauthorized": "You already managed this request",
    "user.company_mismatch":
      "This user doesn't belong to the same organization as yours",
    "target.already_belongs_to_group":
      "This user already belongs to a group now",
    "company.invite_members_via_spreadsheet.invalid_model":
      'The file must contain one column named "email" or "Email".',
    unconfirmed_account:
      "You haven't confirmed your account, please follow the instructions sent to your email adress",
    "nickname.taken": "This nickname is already taken",
    invalid_file_type: "Accepted file types are: .csv, .xls, .xlsx",
    "invite_members_via_spreadsheet.no_record": "No email found in the file",
    "company.invite_members_via_spreadsheet.invalid_models":
      "The file must contain one column named 'email' or 'Email'.",
    "group_name.taken": "This team name is already taken",
    "group_name.invalid":
      "Team names can only have alpha-numerical characters, dashes and underscores",
    "group_members.max_cap": "This team is already full",
    "member.already_belongs_to_group":
      "One of the members you indicated already belogns to a Team",
    "Recaptcha token not valid!": "Oops, something went wrong",
    "The nickname has already been taken.": "This nickname is already taken",
    "The email has already been taken.": "This email is already taken",
    "Email is disposable": "This email is disposable or invalid",
    "User does not exist": "Incorrect email or password",
    "Password mismatch": "Incorrect email or password",
    "errors.auth.token_expired": "Your session has expired, please login again",
    "cyberlab.unauthorized": $localize`:@@cyberlabUnauth:Access denied to Cyberlab`,
    "errors.auth.token_blocked": $localize`:@@token_blocked:Session Ended`,
  };

  apiUrl = environment.backend.account;
  constructor(
    private router: Router,
    private toastService: ToastMessageService,
    private authService: AuthService,
    private redirectionService: RedirectionService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: any) => {
        if ((err as any) instanceof HttpErrorResponse) {
          if (err.error?.errors === "token.unavailable_or_expired") {
            // skip
            throw err;
          }
          if (
            err.error?.message === "account_disabled" ||
            err.errors?.errors === "account_disabled"
          ) {
            this.redirectionService.redirectionSubj.next(true);
            this.router.navigate(["/account-issue/account-disabled"]);
            localStorage.removeItem("currentUser");
          } else if (
            err.error.message === "account_not_confirmed" ||
            err.error.errors === "unconfirmed_account"
          ) {
            localStorage.removeItem("currentUser");
            this.redirectionService.redirectionSubj.next(true);
            this.router.navigate(["/account-issue/account-not-confirmed"]);
          } else if (
            err.error.message === "company_account_not_approved" ||
            err.error.errors === "company_account_not_approved"
          ) {
            localStorage.removeItem("currentUser");
            this.redirectionService.redirectionSubj.next(true);
            this.router.navigate(["/account-issue/company-not-confirmed"]);
            throw new Error("company_not_approved");
          } else if (
            err.error.message === "company_account_rejected" ||
            err.error.errors === "company_account_rejected"
          ) {
            localStorage.removeItem("currentUser");
            this.redirectionService.redirectionSubj.next(true);
            this.router.navigate(["/account-issue/company-rejected"]);
            throw new Error("company_rejected");
          } else if (
            request.url.includes("login") &&
            err.error.errors === "email_taken"
          ) {
            const errorData = err.error.errors_data;
            if (errorData && errorData.provider) {
              localStorage.setItem(
                "register-error-data",
                JSON.stringify(errorData)
              );
            } else {
              localStorage.setItem("login-error", "email_taken");
              localStorage.setItem("login-email", err.error.errors_data.email);
            }
          } else {
            console.log("BackendErrorInterceptor Debugging", err);

            let e = err.error.message; // This is the most common error path

            if (Array.isArray(err.error.errors)) {
              if (
                err.error.errors.length &&
                (err.error.errors[0].code === "errors.auth.token_blocked" ||
                  err.error.errors[0].code === "errors.auth.token_invalid")
              ) {
                throw err;
              }
              if (
                err.error.errors.errors &&
                err.error.errors.errors.length > 0
              ) {
                e = err.error.errors.errors[0];
              }
              if (err.error.errors.message) {
                e = err.error.errors.message;
              }
            } else {
              e = err.error.errors;
              console.log("error here 2", e);
            }

            if (
              request.headers.get("ignoreError") ||
              !request.url.includes(this.apiUrl) ||
              this.isTokenExpired(err)
            ) {
              throw err;
            }

            this.toastService.showError(
              this.errorMap[e]
                ? this.errorMap[e]
                : err.errors ||
                    err.error.errors ||
                    err.error.message ||
                    err.error.errors_data
            );
          }
        }
        throw err;
      })
    );
  }

  isTokenExpired(err: any) {
    return (
      (err as HttpErrorResponse).status === 401 &&
      ((!!(err as HttpErrorResponse).error.errors &&
        !!(err as HttpErrorResponse).error.errors[0] &&
        (err as HttpErrorResponse).error.errors[0].message ===
          "errors.auth.token_expired") ||
        (err as HttpErrorResponse).error.message === "auth.token_expired")
    );
  }
}
