<ul class="flex flex-wrap lg:gap-0 gap-y-3" [ngClass]="position">
  <a
    appRipple
    class="py-4 px-4 cursor-pointer brighter flex items-center mr-6"
    *ngFor="let tab of tabsArray; let i = index"
    [routerLink]="tab.link"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <!-- [ngClass]="activatedTab.index === i ? 'active' : ''" -->
    <!-- (click)="setTab(tab, i)" -->
    <svg-icon
      src="{{ tab.iconPath }}"
      *ngIf="tab.iconPath"
      class="mr-3"
      [svgStyle]="{
        width: '18px',
        height: '18px'
      }"
      [ngClass]="{
        'white-icon': activatedTab.index === i,
        'gray-icon': activatedTab.index !== i
      }"
    ></svg-icon>
    <span
      href="#"
      class="capitalize tab-item-text {{
        activatedTab.index === i ? 'text-white-primary' : 'text-gray-primary'
      }} leading-4 text-gray-primary font-chakra font-bold opacity-50 {{
        customTabTextClass
      }}"
      >{{ tab.name }}</span
    >
  </a>
</ul>
