<!-- header start -->

<div class="w-full" *ngIf="isUserLoaded && !isExternalRoute()">
  <div
    class="flex items-center justify-between w-full px-6 py-3 bg-green-secondary"
  >
    <nav class="flex items-center justify-between gap-8 nav-left-wrapper">
      <div class="flex items-center justify-between">
        <app-navbar-logos-section
          [user]="user"
          [co_branding]="co_branding"
        ></app-navbar-logos-section>
      </div>
    </nav>
    <div class="justify-end">
      <div class="flex items-center">
        <div class="flex items-center gap-4">
          <ng-container *ngIf="userRoles.length">
            <app-wallet-nav
              [showPremiumButton]="showPremiumButton"
              class="hidden min-[780px]:flex"
            ></app-wallet-nav>
            <!-- *ngIf="showWalletNav" -->
          </ng-container>

          <div>
            <app-dropdown-profile-menu
              [user]="user"
            ></app-dropdown-profile-menu>
          </div>

          <div class="relative">
            <div
              *ngIf="notificationNumber > 0"
              class="absolute min-w-[15px] z-10 min-h-[15px] top-[-4px] right-[4px] p-[2px] flex justify-center items-center rounded-full bg-red-primary text-white-primary"
            >
              <span class="text-xxs">
                {{ notificationNumber }}
              </span>
            </div>
            <button
              appRipple
              (click)="openNotificationPopup()"
              class="flex rounded bg-black-transparent min-h-[32px] relative px-2 py-1.5 justify-between text-white-primary cursor-pointer items-center"
            >
              <svg-icon src="assets/icons/notification.svg"></svg-icon>
            </button>
          </div>

          <!-- <button
            (click)="showNavigationMenu()"
            class="flex rounded mr-2 px-2 py-1.5 justify-between text-white-primary cursor-pointer items-center"
          >
            <svg-icon
              src="assets/icons/navigation-menu.svg"
              class="white-icon"
            ></svg-icon>
          </button> -->
        </div>
      </div>
    </div>
  </div>
  <div class="fixed z-10 w-full" [hidden]="!loading">
    <div class="progress">
      <div class="indeterminate"></div>
    </div>
  </div>
</div>
<!-- header End -->
<ng-scrollbar
  #ngScrollbar
  class="app-container scrollbar"
  [ngClass]="{ 'unauthenticated-container': isExternalRoute() }"
>
  <div
    class="w-screen h-full bg-black-primary"
    [ngClass]="{
      'pt-10 lg:px-20 pb-10  m-auto md:px-12 px-8 h-full': !isExternalRoute()
    }"
  >
    <section
      *ngIf="user && companyDetails && !isExternalRoute()"
      class="gap-3 mb-6 grid-auto-fit-[266px] grid"
    >
      <app-statistic-card
        title="Organization wallet"
        i18n-title="@@OrganizationWallet"
        [value]="companyDetails.balance"
        hexagonColor="#EAB308"
        patternId="url(#hexagon_yellow)"
        gradientId="hexagon_yellow"
      >
        <svg-icon
          src="assets/icons/wallet.svg"
          [svgStyle]="{ 'width.px': 40, 'height.px': 40 }"
          class="w-10 h-10 yellow-icon"
        ></svg-icon>
      </app-statistic-card>
      <app-statistic-card
        title="Organization members"
        i18n-title="@@organizationMember"
        [value]="companyDetails.members_count"
        hexagonColor="#387888"
        patternId="url(#hexagon_green)"
        gradientId="hexagon_green"
      >
        <svg-icon
          src="assets/icons/user-icon.svg"
          [svgStyle]="{ 'width.px': 40, 'height.px': 40 }"
          class="w-10 h-10 green-icon"
        ></svg-icon>
      </app-statistic-card>
      <app-statistic-card
        title="Organization teams"
        i18n-title="@@organizationTeams"
        [value]="companyDetails.groups_count"
        hexagonColor="#3b82f6"
        patternId="url(#hexagon_blue)"
        gradientId="hexagon_blue"
      >
        <svg-icon
          src="assets/icons/group-user.svg"
          [svgStyle]="{ 'width.px': 40, 'height.px': 40 }"
          class="w-10 h-10 blue-icon"
        ></svg-icon>
      </app-statistic-card>
      <app-statistic-card
        title="Classrooms"
        i18n-title="@@organizationClassrooms"
        [value]="companyDetails.classrooms_count"
        hexagonColor="#a855f7"
        patternId="url(#hexagon_pink)"
        gradientId="hexagon_pink"
      >
        <svg-icon
          src="assets/icons/group.svg"
          [svgStyle]="{ 'width.px': 40, 'height.px': 40 }"
          class="w-10 h-10 pink-icon"
        ></svg-icon>
      </app-statistic-card>
    </section>
    <ul
      class="relative flex w-full mb-8 border-b border-black-primary"
      *ngIf="isUserLoaded && !isExternalRoute() && !isCheckoutLoading"
    >
      <!-- !isSubscriptionPage -->
      <app-tabs
        [activatedTab]="activatedTab"
        [tabsArray]="accountTabs"
        class="flex"
        (TabChanged)="changeTabs($event)"
      ></app-tabs>
    </ul>
    <router-outlet></router-outlet>
  </div>
</ng-scrollbar>
<app-toast></app-toast>
