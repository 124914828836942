import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError, BehaviorSubject, EMPTY } from "rxjs";
import { catchError, filter, take, switchMap } from "rxjs/operators";
import { Router } from "@angular/router";

import { AuthService } from "@services/auth/auth.service";

import { RefreshTokenResp } from "@interfaces/refresh-token";

import { environment } from "@environments/environment";
import { JwtService } from "@services/jwt.service";
import { ToastMessageService } from "@services/toast-message.service";

@Injectable({
  providedIn: "root",
})
export class HttpErrorInterceptorService implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(
    private authService: AuthService,
    private router: Router,
    private jwtService: JwtService,
    private toastService: ToastMessageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse && err.error) {
          // This long condition is due to the nature of the backend's response
          if (
            (err as HttpErrorResponse).status === 401 &&
            ((!!(err as HttpErrorResponse).error.errors &&
              !!(err as HttpErrorResponse).error.errors[0] &&
              (err as HttpErrorResponse).error.errors[0].message ===
                "errors.auth.token_expired") ||
              (err as HttpErrorResponse).error.message === "auth.token_expired")
          ) {
            if (environment.settings.debuging) {
              console.log(
                "[Http Error Interceptor]%c Refreshing token..",
                "color:orange"
              );
            }
            if (!this.isRefreshing) {
              this.isRefreshing = true;
              this.refreshTokenSubject.next(null);
              return this.authService.refreshToken().pipe(
                switchMap((r: RefreshTokenResp) => {
                  this.isRefreshing = false;
                  const newToken = r.data.new_token;
                  if (newToken) {
                    this.jwtService.jwt = newToken;
                    const userData = localStorage.getItem("currentUser");
                    let currentUser: any = null;
                    if (userData) {
                      currentUser = JSON.parse(userData);
                      if (userData) {
                        currentUser.token = newToken;
                        localStorage.setItem(
                          "currentUser",
                          JSON.stringify(currentUser)
                        );
                      }
                    }

                    this.refreshTokenSubject.next(newToken);
                    if (environment.settings.debuging) {
                      console.log(
                        "[Http Error Interceptor]%c Token refreshed",
                        "color:green"
                      );
                    }
                    return next.handle(this.addToken(request, newToken));
                  } else {
                    if (environment.settings.autoLogout) {
                      //
                      this.toastService.showError(
                        $localize`:@@session_ended:Session Ended`
                      );
                      this.authService.logout("login");
                    }
                    return EMPTY;
                  }
                }),
                catchError((e) => {
                  this.isRefreshing = false;
                  if (environment.settings.autoLogout) {
                    this.toastService.showError(
                      $localize`:@@session_ended:Session Ended`
                    );
                    this.authService.logout("login");
                  }
                  return EMPTY;
                })
              );
            } else {
              return this.refreshTokenSubject.pipe(
                filter((token) => token != null),
                take(1),
                switchMap((jwt) => {
                  return next.handle(this.addToken(request, jwt));
                })
              );
            }
          }
          if (
            (err as HttpErrorResponse).status === 401 &&
            (err as HttpErrorResponse).error.errors[0].message ===
              "errors.auth.token_blocked"
          ) {
            if (environment.settings.autoLogout) {
              this.authService.logout("login");
            }
          }
        }
        throw err;
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
